.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.pattern-background {
  background-color: #ffffff;
  opacity: 1;
  background-image:  linear-gradient(30deg, #f4f4f4 12%, transparent 12.5%, transparent 87%, #f4f4f4 87.5%, #f4f4f4), linear-gradient(150deg, #f4f4f4 12%, transparent 12.5%, transparent 87%, #f4f4f4 87.5%, #f4f4f4), linear-gradient(30deg, #f4f4f4 12%, transparent 12.5%, transparent 87%, #f4f4f4 87.5%, #f4f4f4), linear-gradient(150deg, #f4f4f4 12%, transparent 12.5%, transparent 87%, #f4f4f4 87.5%, #f4f4f4), linear-gradient(60deg, #f4f4f477 25%, transparent 25.5%, transparent 75%, #f4f4f477 75%, #f4f4f477), linear-gradient(60deg, #f4f4f477 25%, transparent 25.5%, transparent 75%, #f4f4f477 75%, #f4f4f477);
  background-size: 30px 53px;
  background-position: 0 0, 0 0, 15px 26px, 15px 26px, 0 0, 15px 26px;
}

.full {
  height: 100%;
}
